import { mounterFor, setSurveyOptionHeight } from './util';
import Type from 'union-type';
import * as R from 'ramda';
import Question from './question';
import {
  h,
} from "snabbdom";
import globalState from './globalState';
 
import arrowLeftSVG from '../../assets/images/arrow-left.svg';
import arrowRightSVG from '../../assets/images/arrow-right.svg';
import angleRight from '../../assets/images/angle-right.svg';
 
const container = document.querySelector(".js-survey");
 
const GlobalQuestion = globalState.withGlobalState(Question);
 
// STATE
// {
//   queryBase: string
//   vertical: string
//   questions: []question
//   activeQuestion: int
//   isMobile: bool
// }
 
const init = () => {
  const surveyConfig = JSON.parse(document.querySelector('#survey-config').dataset.data);
  for (const q of surveyConfig.questions) {
    q.options = (q.options || []).map((opt, idx) => {
      if (typeof opt === 'string') {
        return {text: opt, details: '', selected: false, image: ''};
      }
      // preload images so they don't flash when we transition between pages
      new Image().src = opt.image;
      return {...opt, idx, selected: false}
    });
  }
  return {
    ...surveyConfig,
    activeQuestion: 0,
  }
}
 
const Action = Type({
  MoveRight: [],
  Skip: [],
  MoveLeft: [],
  UpdateQuestion: [Object],
})
 
function getQuestionValue(question) {
  if (question.type === 'text') return question.response;
  return question.options.filter(opt => opt.selected).map(opt => opt.text).join(', ')
}
 
function constructQuery(state) {
  let query = state.queryBase || '';
  let filterObj = {};
  let sortObj = {};

  // Change values passed to API w/o changing labels
  const queryValueMap = {
    'Axe Throwing' : 'Axe Throwing Insurance',
    'Mechanical Bull Riding' : 'Mechanical Bull Insurance',
    'Voluntary Benefits' : 'Voluntary Benefit Plans',
    'Healthcare' : 'Healthcare & Hospitals',
    'Self-Storage Facilities': 'Self Storage Facilities',
    'Legal Malpractice' : 'Law Firms'
  };
  for (const question of state.questions) {
    let value = getQuestionValue(question);
    // Change api query value for non-sorting questions only
    if(queryValueMap[value] && question.sortingcustomfield != 'c_myDivision1') {
      value = queryValueMap[value]
    }
    // Add question info to query params - last question answer goes before penultimate one in query
    // if other sorting CF filled out place info beofre it in query string
    if(question.sortingcustomfield && question.sortingcustomfield == 'c_myDivision1' && query.indexOf('%20on%20') >= 0) {
      query = query.slice(0, query.indexOf('%20on%20')) + (value ? '%20' + encodeURIComponent(question.format.replace('%s', getQuestionValue(question))) : '') + query.slice(query.indexOf('%20on%20'));
    } else {
      query += value ? '%20' + encodeURIComponent(question.format.replace('%s', getQuestionValue(question))) : '';
    }

    // If associated CF then add to filters
    const associatedCF = question.customfield ? question.customfield : null;
    // If sortingCF then use to sort
    const sortingCF = question.sortingcustomfield ? question.sortingcustomfield : null;
    if(associatedCF && value) {
      if(!filterObj[associatedCF]) {
        filterObj[associatedCF] = [];
      }
      let newFilter = {};
      newFilter[associatedCF] = {"$eq": value};
      filterObj[associatedCF].push(newFilter)
    } else if (sortingCF && value) {
      if(!sortObj[sortingCF]) {
        sortObj[sortingCF] = [];
      }
      sortObj[sortingCF].push(value);
    }
  }
  const stringFilters = JSON.stringify(filterObj);
  const sortInfo = JSON.stringify(sortObj);
  query += '&facetFilters=' + encodeURIComponent(stringFilters) + '&sortingInfo=' + encodeURIComponent(sortInfo);
  return query.trim();
}
 
const surveyView = R.curry((action$, state) => {
  const vert = state.vertical;
  const queryUrl = `/${vert}?query=${constructQuery(state)}`;
  const submitButton = () => {
    return h('a.Survey-submit', { props: { href: queryUrl } }, ['Submit']);
  };
  const rightButton = () => {
    return h('button.Survey-right', { 
      style: {backgroundImage: `url(${arrowRightSVG})`},
      on: {click: () => {
        action$(Action.MoveRight);
        setSurveyOptionHeight();
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth'
        });
      }}, 
        props: { type: 'button' } 
      }, [
      h('span.sr-only', {}, ['next question'])
    ]);
  };

  const isFirstQuestion = state.activeQuestion === 0;
  const isLastQuestion = state.activeQuestion === state.questions.length - 1;

  const surveyNavBar = (modifier) =>{
    return h(`div.Survey-navBar${modifier}`, {}, [
      isFirstQuestion ? h('span') : h('button.Survey-left', { 
        style: {backgroundImage: `url(${arrowLeftSVG})`},
        on: {click: () => {
          action$(Action.MoveLeft);
          setSurveyOptionHeight();
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
          
        }}, 
        props: { type: 'button' }
      }, [
        h('span.sr-only', {}, ['previous question'])
      ]),
      isLastQuestion ? h('a.Survey-skip', { style: {}, props: { href: queryUrl } }, [
        'Skip this question',
        h('span.Survey-angle', {style: {backgroundImage: `url(${angleRight})`}}, [])
      ]) : h('button.Survey-skip', { style: {}, on: {click: () => {
          action$(Action.Skip);
          setSurveyOptionHeight();
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }}, props: { type: 'button' } }, [
        'Skip this question',
        h('span.Survey-angle', {style: {backgroundImage: `url(${angleRight})`}}, []),
      ]),
      isLastQuestion ? submitButton() : rightButton(),
    ]);
  }
 
  return h('div.Survey', [
    surveyNavBar('Top'),
    h('form.Survey-form', [
      h('div.Survey-progress', {}, [
        h('div.Survey-progressFill', {style: {width: `${100 * (state.activeQuestion + 1) / state.questions.length}%`}}, [])
      ]),
      h('div.Survey-question', {}, [
        GlobalQuestion.view(action => action$(Action.UpdateQuestion({idx: state.activeQuestion, action: action})), state.questions[state.activeQuestion]),
      ]),
    ]),
    surveyNavBar('Bottom')
  ])
})
 
const update = (state, action) => {
  return Action.case({
    Skip: (state) => ({
       ...state, 
       activeQuestion: Math.min(state.questions.length - 1, state.activeQuestion + 1),
       questions: state.questions.map((q, i) => state.activeQuestion === i ? Question.update(q, Question.Action.Clear) : q)}),
    MoveLeft: (state) => ({ ...state, activeQuestion: Math.max(0, state.activeQuestion - 1) }),
    MoveRight: (state) => ({ ...state, activeQuestion: Math.min(state.questions.length - 1, state.activeQuestion + 1) }),
    UpdateQuestion: (data, state) => {
      const searchbar = document.querySelector('.js-yext-query');
      const newState = { ...state, questions: state.questions.map((q, idx) => idx === data.idx ? Question.update(q, data.action) : q)}
      if (searchbar) {
        searchbar.value = constructQuery(newState);
        searchbar.scrollLeft = searchbar.scrollWidth;
      }
      return newState;
    },
  }, action, state)
}
 
const surveyMounter = mounterFor({
  view: surveyView,
  update: update,
});
 
surveyMounter(init(), container);
