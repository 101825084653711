/** @module @yext/components-util */

/**
 * Throttle an event to be dispatched at most once per animation frame. Adapted from:
 * {@link https://developer.mozilla.org/en-US/docs/Web/Events/resize}
 */
 export class Throttle {
  /**
   * @param {string} eventName Name of the event to listen to
   * @param {string} customName Name of the custom event to fire
   * @param {HTMLDocument|HTMLElement} scope Scope to listen for the event
   */
  constructor(eventName, customName, scope) {
    this.eventName = eventName;
    this.customName = customName;
    this.scope = scope;
    this.running = false;

    this.listener = () => {
      if (this.running) { return; }
      this.running = true;
      requestAnimationFrame(() => {
        this.scope.dispatchEvent(new CustomEvent(this.customName));
        this.running = false;
      });
    };
  }

  /**
   * Start listening for the event and dispatching custom events
   */
  start() {
    this.scope.addEventListener(this.eventName, this.listener);
  }

  /**
   * Stop listening for the event and dispatching custom events
   */
  end() {
    this.scope.removeEventListener(this.eventName, this.listener);
  }
}
